.react-calendar-timeline .rct-header-root {
    background: #3f51b5;
}

.circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
    margin: 2px;
}

.spacer {
    width: 50px;
    display: block;
}

.legend-item {
    display: inline;
    margin-left: 20px;
}