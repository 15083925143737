/* ========== Calendar event ========== */
.calendar__event--block {
    background: repeating-linear-gradient(45deg, #606dbc, #606dbc 10px, #465298 10px, #465298 20px);
}

.rbc-allday-cell {
    display: none;
}

#selectfield-employee {
    padding: 10px 40px 10px 20px;
}